/* eslint-disable react-hooks/rules-of-hooks */
import { NavLink, useLocation } from "react-router-dom";
import {
  AbsoluteCenter,
  Box,
  Divider,
  Flex,
  HStack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

export function SidebarLinks(props) {
  let location = useLocation();
  let activeColor = useColorModeValue("#A5805B", "white");
  let inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.400");
  let activeIcon = useColorModeValue("#A5805B", "white");
  let textColor = useColorModeValue("gray.700", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
  let sidebarBgColor = useColorModeValue("gray.100", "#fff");
  const { routes, setOpenSidebar, openSidebar } = props;

  const user = JSON.parse(localStorage.getItem("user"));

  // Determine if the route is active
  const activeRoute = (routeName) => location.pathname.includes(routeName);

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route?.category) {
        return (
          <React.Fragment key={index}>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight="bold"
              mx="auto"
              ps={{ sm: "10px", xl: "16px" }}
              pt="18px"
              pb="10px"
            >
              {route.name}
            </Text>
            {createLinks(route.items)}
          </React.Fragment>
        );
      } else if (!route?.under && user?.role && route.layout?.includes(`/${user.role}`)) {
        return (
          <NavLink key={index} to={route.path} style={{ textDecoration: "none" }}>
            {route.separator && (
              <Box position="relative" margin="20px 0">
                <Divider />
                <AbsoluteCenter
                  textTransform={"capitalize"}
                  bg={useColorModeValue("white", "gray.800")}
                  width={"max-content"}
                  padding="0 10px"
                  textAlign={"center"}
                >
                  {route.separator}
                </AbsoluteCenter>
              </Box>
            )}
            <Box
              backgroundColor={activeRoute(route.path) ? sidebarBgColor : ""}
              ps={"25px"}
              pb={"6px"}
              pt={"10px"}
              // borderRadius={activeRoute(route.path) ? "full" : ""}
              transition="background-color 0.3s ease"
              _hover={{ backgroundColor: useColorModeValue("gray.200", "gray.600") }}
            >
              <HStack spacing={openSidebar ? "22px" : "26px"} py="5px">
                {/* Render icon and text */}
                {openSidebar ? (
                  <Flex w="100%" alignItems="center">
                    <Box color={activeRoute(route.path) ? activeIcon : textColor} me="18px">
                      {route.icon}
                    </Box>
                    <Text
                      me="auto"
                      pb="3px"
                      whiteSpace="nowrap"
                      width="190px"
                      color={activeRoute(route.path) ? activeColor : textColor}
                      fontWeight={activeRoute(route.path) ? "bold" : "normal"}
                      // fontSize={activeRoute(route.path) ? "22px" : "18px"}
                    >
                      <Tooltip hasArrow label={route.name} placement="right">
                        {route.name}
                      </Tooltip>
                    </Text>
                  </Flex>
                ) : (
                  <Flex w="100%" alignItems="center">
                    <Box color={activeRoute(route.path) ? activeIcon : textColor} me="18px">
                      {route.icon}
                    </Box>
                  </Flex>
                )}
                {/* Active Route Indicator */}
                <Box
                  w="4px"
                  bg={activeRoute(route.path) ? brandColor : "transparent"}
                  borderRadius="5px"
                  transition="all 0.3s ease"
                />
              </HStack>
            </Box>
          </NavLink>
        );
      }
    });
  };

  return createLinks(routes);
}

export default SidebarLinks;

// /* eslint-disable react-hooks/rules-of-hooks */
// import { NavLink, useLocation } from "react-router-dom";
// // chakra imports
// import { AbsoluteCenter, Box, Divider, Flex, HStack, Text, Tooltip, useColorModeValue, useDisclosure } from "@chakra-ui/react";
// import React from "react";

// export function SidebarLinks(props) {
//   let location = useLocation();
//   let activeColor = useColorModeValue("#A5805B", "white");
//   let inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
//   let activeIcon = useColorModeValue("#A5805B", "white");
//   let textColor = useColorModeValue("secondaryGray.500", "white");
//   let brandColor = useColorModeValue("brand.500", "brand.400");
//   let sidebarBgColor = useColorModeValue("gray.200", "brand.200");
//   const { isOpen, onOpen, onClose } = useDisclosure();

//   const user = JSON.parse(localStorage.getItem("user"));

//   const { routes, setOpenSidebar, openSidebar } = props;

//   const activeRoute = (routeName) => {
//     return location.pathname?.includes(routeName);
//   };

//   const createLinks = (routes) => {
//     return routes?.map((route, index) => {
//       if (route?.category) {
//         return (
//           <React.Fragment key={index}>
//             <Text
//               fontSize={"md"}
//               color={activeColor}
//               fontWeight="bold"
//               mx="auto"
//               ps={{
//                 sm: "10px",
//                 xl: "16px",
//               }}
//               pt="18px"
//               pb="10px"
//             >
//               {route?.name}
//             </Text>
//             {createLinks(route?.items)}
//           </React.Fragment>
//         );
//       } else if (!route?.under && user?.role && route?.layout?.includes(`/${user?.role}`)) {
//         return (
//           <NavLink key={index} to={route.path} style={{ textDecoration: 'none' }}>
//             {route?.separator && (
//               <Box position="relative" margin="20px 0">
//                 <Divider />
//                 <AbsoluteCenter
//                   textTransform={"capitalize"}
//                   bg="white"
//                   width={"max-content"}
//                   padding="0 10px"
//                   textAlign={"center"}
//                 >
//                   {route?.separator}
//                 </AbsoluteCenter>
//               </Box>
//             )}
//             {route.icon ? (
//               <Box
//                 backgroundColor={
//                   activeRoute(route.path.toLowerCase()) ? sidebarBgColor : ""
//                 }
//                 ps={"25px"}
//                 pb={"6px"}
//                 pt={"10px"}
//               >
//                 <HStack
//                   spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
//                   py="5px"
//                   _hover={{ bg: useColorModeValue('gray.100', 'gray.700'), borderRadius: '8px' }}
//                 >
//                   {openSidebar ? (
//                     <Flex w="100%" alignItems="center" justifyContent="center">
//                       <Box
//                         color={
//                           activeRoute(route.path.toLowerCase())
//                             ? activeIcon
//                             : textColor
//                         }
//                         me="18px"
//                       >
//                         {route.icon}
//                       </Box>
//                       <Text
//                         me="auto"
//                         pb={"3px"}
//                         textOverflow={"ellipsis"}
//                         textTransform={"capitalize"}
//                         overflowX="hidden"
//                         whiteSpace="nowrap"
//                         width="190px"
//                         color={
//                           activeRoute(route.path.toLowerCase())
//                             ? activeColor
//                             : textColor
//                         }
//                         fontWeight={
//                           activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
//                         }
//                       >
//                         <Tooltip hasArrow label={route.name}>
//                           {route.name}
//                         </Tooltip>
//                       </Text>
//                     </Flex>
//                   ) : (
//                     <Flex w="100%" alignItems="center" justifyContent="center">
//                       <Box
//                         color={
//                           activeRoute(route.path.toLowerCase())
//                             ? activeIcon
//                             : textColor
//                         }
//                         me="18px"
//                       >
//                         {route.icon}
//                       </Box>
//                     </Flex>
//                   )}
//                   <Box
//                     w="4px"
//                     bg={
//                       activeRoute(route.path.toLowerCase())
//                         ? brandColor
//                         : brandColor
//                     }
//                     borderRadius="5px"
//                   />
//                 </HStack>
//               </Box>
//             ) : (
//               <Box>
//                 <HStack
//                   spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
//                   py="5px"
//                   ps="10px"
//                   _hover={{ bg: useColorModeValue('gray.100', 'gray.700'), borderRadius: '8px' }}
//                 >
//                   <Text
//                     me="auto"
//                     color={
//                       activeRoute(route.path.toLowerCase())
//                         ? activeColor
//                         : inactiveColor
//                     }
//                     fontWeight={
//                       activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
//                     }
//                   >
//                     {route.name}
//                   </Text>
//                   <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
//                 </HStack>
//               </Box>
//             )}
//           </NavLink>
//         );
//       }
//     });
//   };

//   return createLinks(routes);
// }

// export default SidebarLinks;
