import { Icon } from "@chakra-ui/react";
import { HiUsers } from "react-icons/hi";
import { MdOutlineSupportAgent, MdHandyman } from "react-icons/md";
import { IoHomeOutline, IoPeopleOutline, IoSettingsOutline, IoQrCodeOutline,IoShieldOutline } from "react-icons/io5";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { FaBuildingUser } from "react-icons/fa6";
import { PiPhoneCallBold } from "react-icons/pi";
import {
  MdContacts,
  MdHome,
  MdInsertChartOutlined,
  MdLeaderboard,
  MdLock
} from "react-icons/md";

// icon
import React from "react";
// import { AiFillFolderOpen, AiOutlineMail } from "react-icons/ai";
import { FaWpforms } from "react-icons/fa";
import { FaCreativeCommonsBy, FaMobileAlt } from "react-icons/fa";
import { ROLE_PATH } from "./roles";
import ChangeImage from "views/admin/image";
import Validation from "views/admin/validation";
import CustomField from "views/admin/customField";
import TableField from "views/admin/tableField";
import { TbExchange, TbTableColumn } from "react-icons/tb";
import { GrValidate } from "react-icons/gr";

// Admin Imports
const MainDashboard = React.lazy(() => import("views/admin/default"));

// My component

const User = React.lazy(() => import("views/admin/users"));
const Profile = React.lazy(() => import("views/admin/users/View"));

const Role = React.lazy(() => import("views/admin/role"));


const Report = React.lazy(() => import("views/admin/reports"));

// Auth Imports
const SignInCentered = React.lazy(() => import("views/auth/signIn"));
// admin setting 
const AdminSetting = React.lazy(() => import("views/admin/adminSetting"));


// Import your ticket components
const Tickets = React.lazy(() => import("views/admin/ticket"));
const TicketView = React.lazy(() => import("views/admin/ticket/components/eventView"));
const AddTicket = React.lazy(() => import("views/admin/ticket/components/AddEdit"));
const EditTicket = React.lazy(() => import("views/admin/ticket/components/edit"));
// Import Settings Module
const Settings = React.lazy(() => import("views/admin/settings/index"));
// Import Projects Module
const Projects = React.lazy(() => import("views/admin/projects/index"))
const ProjectView = React.lazy(() => import("views/admin/projects/View"))
const ProjectUnitsView = React.lazy(() => import("views/admin/projects/Components/PhaseView"))
// Import News Module
const app = React.lazy(() => import("views/admin/app/index"))
// Import Customer Module
const Customer = React.lazy(() => import("views/admin/customer/index"))
const discover = React.lazy(() => import("views/admin/discover/index"))
const qrCode = React.lazy(() => import("views/admin/qr/index.js"))
const commissioners = React.lazy(() => import("views/admin/commissioners-units/index"))

const EditUnit = React.lazy(() => import("views/admin/projects/Components/editUnit"))
const ViewUnit = React.lazy(() => import("views/admin/projects/Components/viewUnit"))

const Lead = React.lazy(() => import("views/admin/leads/index"))


const routes = [
  // ========================== Dashboard ==========================
  {
    name: "Dashboard",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/default",
    icon: <Icon as={IoHomeOutline} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  //========================= Settings =============================
  {
    name: "Settings",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/settings",
    icon: <Icon as={IoSettingsOutline} width='20px' height='20px' color='inherit' />,
    component: Settings,
  },
  // {
  //   name: "Dashboard",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/default",
  //   icon: <Icon as={IoHomeOutline} width='20px' height='20px' color='inherit' />,
  //   component: MainDashboard,
  // },
  //========================= Project ==============================
  {
    name: "Projects/Units",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/projects",
    icon: <Icon as={HiOutlineBuildingOffice2} width='20px' height='20px' color='inherit' />,
    component: Projects,
  },
  {
    name: "Projects/Units",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "projects",
    parentName: "Projects/Units",
    path: "/projectView/:id",
    component: ProjectView,
  },
  {
    name: "Projects/Units",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Projects/Units",
    parentName: "Projects/Units",
    // path: "/:projectId/phase/:phaseId",
    path: "/project-units/:projectId",
    component: ProjectUnitsView,
  },
  {
    name: "Projects/Units",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Projects/Units",
    parentName: "Projects/Units",
    path: "/project-units/unit/edit/:unitId",
    component: EditUnit,
  },
  {
    name: "Projects/Units",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Projects/Units",
    parentName: "Projects/Units",
    path: "/project-units/unit/view/:unitId",
    component: ViewUnit,
  },
  // ========================== Admin Layout ==========================
  // ------------- Property Routes ------------------------
  // {
  //   name: "Properties",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/properties",
  //   icon: <Icon as={HiOutlineBuildingOffice} width='20px' height='20px' color='inherit' />,
  //   component: Property,
  // },
  // {
  //   name: "Properties ",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   parentName: "Properties",
  //   under: "properties",
  //   path: "/propertyView/:id",
  //   component: PropertyView,
  // },
  // {
  //   name: "Property Import",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   both: true,
  //   under: "properties",
  //   parentName: "Properties",
  //   path: "/propertyImport",
  //   component: PropertyImport,
  // },
  // ------------- lead Routes ------------------------
  {
    name: "Leads",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/lead",
    icon: <Icon as={MdLeaderboard} width='20px' height='20px' color='inherit' />,
    component: Lead,
  },
  // {
  //   name: "Leads",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "lead",
  //   parentName: "Leads",
  //   path: "/leadView/:id",
  //   component: LeadView,
  // },
  // {
  //   name: "Calls",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/phone-call",
  //   icon: <Icon as={PiPhoneCallBold} width='20px' height='20px' color='inherit' />,
  //   component: PhoneCall,
  // },
  // {
  //   name: "Calls",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "phone-call",
  //   parentName: "Calls",
  //   path: "/phone-call/:id",
  //   component: PhoneCallView,
  // },
  // {
  //   name: "Lead Import",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "lead",
  //   parentName: "Leads",
  //   path: "/leadImport",
  //   component: LeadImport,
  // },
  // --------------- contact Routes --------------------
  // {
  //   name: "Contacts",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/contacts",
  //   icon: <Icon as={MdContacts} width='20px' height='20px' color='inherit' />,
  //   component: Contact,
  // },
  // {
  //   name: "Contacts",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   under: "contacts",
  //   parentName: "Contacts",
  //   path: "/contactView/:id",
  //   component: ContactView,
  // },
  // {
  //   name: "Contact Import",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   both: true,
  //   under: "contacts",
  //   parentName: "Contacts",
  //   path: "/contactImport",
  //   component: ContactImport,
  // },
  //------------------------------News---------------------------------------
  {
    name: "App Management",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/app-management",
    icon: <Icon as={FaMobileAlt} width='20px' height='20px' color='inherit' />,
    component: app,
  },
  {
    name: "Discover Places",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/discover-places",
    icon: <Icon as={IoPeopleOutline} width='20px' height='20px' color='inherit' />,
    component: discover,
  },
  {
    name: "Visitors Pass",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/qrCodes",
    icon: <Icon as={IoQrCodeOutline} width='20px' height='20px' color='inherit' />,
    component: qrCode,
  },
  // -----------------------------Admin setting-------------------------------------
  {
    name: "Admin Setting",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    parentName: "admin",
    under: "admin",
    path: "/admin-setting",
    component: AdminSetting,
  },

  // ------------- Ticket Routes ------------------------
  {
    name: "Tickets",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/ticket",
    icon: <Icon as={MdOutlineSupportAgent} width='20px' height='20px' color='inherit' />,
    component: Tickets,
  },
  {
    name: "Tickets",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Ticket",
    parentName: "Ticket",
    path: "/ticket/notesview/:id",
    component: TicketView,
  },
  {
    name: "Tickets",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Ticket",
    parentName: "Ticket",
    path: "/ticket/add",
    component: AddTicket,
  },
  {
    name: "Tickets",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Ticket",
    parentName: "Ticket",
    path: "/ticket/edit/:id",
    component: EditTicket,
  },
  //-----------------------------Customer Route--------------------------
  {
    name: "Commissioners",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/commissioners-units",
    // under: "Discover Places",
    icon: <Icon as={MdHandyman} width='20px' height='20px' color='inherit' />,
    component: commissioners,
  },
  {
    name: "Customers Management",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/Customers",
    icon: <Icon as={FaBuildingUser} width='20px' height='20px' color='inherit' />,
    component: Customer,
  },
  // ------------- Roles Routes ------------------------
  {
    name: "Roles",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/role",
    under: "role",
    icon: <Icon as={FaCreativeCommonsBy} width='20px' height='20px' color='inherit' />,
    component: Role,
  },
  // {
  //   name: "Custom Fields",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/custom-Fields",
  //   under: "customField",
  //   icon: <Icon as={FaWpforms} width='20px' height='20px' color='inherit' />,
  //   component: CustomField,
  // },
  {
    name: "Change Images",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/change-images",
    under: "image",
    icon: <Icon as={TbExchange} width='20px' height='20px' color='inherit' />,
    component: ChangeImage,
  },
  // {
  //   name: "Validation",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/validations",
  //   under: "Validation",
  //   icon: <Icon as={GrValidate} width='20px' height='20px' color='inherit' />,
  //   component: Validation,
  // },
  // {
  //   name: "Table Fields",
  //   layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
  //   path: "/table-field",
  //   under: "tableField",
  //   icon: <Icon as={TbTableColumn} width='20px' height='20px' color='inherit' />,
  //   component: TableField,
  // },
  // ----------------- Reporting Layout -----------------
  {
    name: "Reporting and Analytics",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/reporting-analytics",
    icon: <Icon as={MdInsertChartOutlined} width='20px' height='20px' color='inherit' />,
    component: Report,
  },
  // ------------- user Routes ------------------------
  {
    name: "Users",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    path: "/user",
    under: "user",
    icon: <Icon as={HiUsers} width='20px' height='20px' color='inherit' />,
    component: User,
  },
  {
    name: "Profile",
    layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
    under: "Users",
    parentName: "Users",
    path: "/profile/:id",
    component: Profile,
  },
  // ========================== User layout ==========================

  // ========================== auth layout ==========================
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
  },
];

export default routes;
