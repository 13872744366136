import React, { useState, useEffect } from 'react';
import { Badge, Tooltip, Popover, List, Button, Typography, Empty } from 'antd';
import { FiBell } from 'react-icons/fi';
import axios from 'axios';
import { constant } from '../../constant';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const BASE_URL = `${constant.baseUrl}api`;
const api = axios.create({ baseURL: BASE_URL });

const NotificationIcon = styled(FiBell)`
  font-size: 24px;
  color: #a2987a;
  cursor: pointer;
`;

const NotificationsContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const { Text } = Typography;

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') console.log('Notification permission granted.');
      });
    }

    // Fetch notifications on initial load
    fetchNotifications();

    const ws = new WebSocket(`${process.env.REACT_APP_BASE_URL_WS}`);
    ws.onopen = () => console.log('WebSocket connection opened');
    ws.onmessage = (event) => {
      const newNotification = JSON.parse(event.data);
      setNotifications((prev) => [newNotification, ...prev]);

      const audio = new Audio('/pop-up-bubble-gfx-sounds-1-00-00.wav');
      audio.play().catch((error) => console.error("Failed to play sound:", error));

      if (Notification.permission === 'granted') {
        new Notification('New Notification', {
          body: newNotification.contents,
          icon: '/notification_icon.png',
        });
      }
    };

    ws.onerror = (error) => console.error('WebSocket error:', error);
    ws.onclose = () => console.log('WebSocket connection closed');
    return () => ws.close();
  }, []);

  const fetchNotifications = async () => {
    try {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const userId = JSON.parse(storedUser).id;
        const response = await api.get(`/notifications/notifications/${userId}`);
        setNotifications(response.data.data);
      } else {
        console.error('User not found in local storage');
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handlePopoverToggle = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  // const markAsRead = async (notification_id, url) => {
  //   try {
  //     const storedUser = localStorage.getItem('user');
  //     if (storedUser) {
  //       const userId = JSON.parse(storedUser).id;
  //       await api.put(`/notifications/mark-as-seen`, {
  //         NotificationId: notification_id,
  //         NhiUserId: userId
  //       });
  //       setNotifications((prev) => prev.filter((notif) => notif.notification_id !== notification_id));
  //     }
  //     navigate(url);
  //   } catch (error) {
  //     console.error('Error marking notification as read:', error);
  //   }
  // };
  const markAsRead = async (notification_id, url) => {
    try {
      console.log("🚀 ~ markAsRead ~ url:", url);  // Check if URL is correct
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const userId = JSON.parse(storedUser).id;
        await api.put(`/notifications/mark-as-seen`, {
          NotificationId: notification_id,
          NhiUserId: userId
        });
        setNotifications((prev) => prev.filter((notif) => notif.notification_id !== notification_id));
      }
      // setIsPopoverOpen(false); // Close the popover
      console.log("Navigating to:", url);

      navigate(url); // Now navigate to the URL
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };
  
  const notificationList = (
    <div style={{ width: 320, maxHeight: 400, overflowY: 'auto' }}>
      {notifications.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={notifications}
          renderItem={(notif) => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  onClick={() => markAsRead(notif.notification_id, notif.url)}
                  key="mark-read"
                  style={{ color: '#A5805B' }}
                >
                  Mark as read
                </Button>,
              ]}
            >
              <List.Item.Meta
                title={<Text strong>{notif.contents}</Text>}
                description={<Text type="secondary">Just now</Text>}
              />
            </List.Item>
          )}
        />
      ) : (
        <Empty description="No new notifications" style={{ padding: 20 }} />
      )}
    </div>
  );

  return (
    <NotificationsContainer>
      <Popover
        content={notificationList}
        title="Notifications"
        trigger="click"
        visible={isPopoverOpen}
        onVisibleChange={handlePopoverToggle}
        placement="bottomRight"
      >
        <Badge count={notifications.length} overflowCount={9}>
          <Tooltip title="Notifications">
            <NotificationIcon />
          </Tooltip>
        </Badge>
      </Popover>
    </NotificationsContainer>
  );
}

export default Notifications;

// import React, { useState, useEffect } from 'react';
// import { Badge, Tooltip, Popover, List, Button, Typography, Empty } from 'antd';
// import { FiBell } from 'react-icons/fi';
// import { PiBellThin } from "react-icons/pi";

// import axios from 'axios';
// import { constant } from '../../constant';
// import { useNavigate } from 'react-router-dom';
// import styled from 'styled-components';

// const BASE_URL = `${constant.baseUrl}api`;
// const api = axios.create({ baseURL: BASE_URL });

// const NotificationIcon = styled(FiBell)`
//   font-size: 24px;
//   color: #a2987a;
//   cursor: pointer;
// `;

// const NotificationsContainer = styled.div`
//   position: relative;
//   display: inline-block;
// `;

// const { Text } = Typography;

// function Notifications() {
//   const [notifications, setNotifications] = useState([]);
//   const [isPopoverOpen, setIsPopoverOpen] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
//       Notification.requestPermission().then((permission) => {
//         if (permission === 'granted') console.log('Notification permission granted.');
//       });
//     }

//     const ws = new WebSocket(`${process.env.REACT_APP_BASE_URL_WS}`);
//     ws.onopen = () => console.log('WebSocket connection opened');
//     ws.onmessage = (event) => {
//       const newNotification = JSON.parse(event.data);
//       setNotifications((prev) => [newNotification, ...prev]);

//       const audio = new Audio('/notification_sound.wav');
//       audio.play().catch((error) => console.error("Failed to play sound:", error));

//       if (Notification.permission === 'granted') {
//         new Notification('New Notification', {
//           body: newNotification.contents,
//           icon: '/notification_icon.png',
//         });
//       }
//     };

//     ws.onerror = (error) => console.error('WebSocket error:', error);
//     ws.onclose = () => console.log('WebSocket connection closed');
//     return () => ws.close();
//   }, []);

//   const fetchNotifications = async () => {
//     try {
//       const storedUser = localStorage.getItem('user');
//       if (storedUser) {
//         const userId = JSON.parse(storedUser).id;
//         const response = await api.get(`/notifications/notifications/${userId}`);
//         setNotifications(response.data.data);
//         console.log("🚀 ~ fetchNotifications ~ response:", response)
//       } else {
//         console.error('User not found in local storage');
//       }
//     } catch (error) {
//       console.error('Error fetching notifications:', error);
//     }
//   };


//   const handlePopoverToggle = () => {
//     if (!isPopoverOpen) fetchNotifications();
//     setIsPopoverOpen(!isPopoverOpen);
//   };

//   const markAsRead = async (notification_id, url) => {
//     try {
//       console.log("🚀 ~ markAsRead ~ url:", url)
//       const storedUser = localStorage.getItem('user');
//       if (storedUser) {
//         const userId = JSON.parse(storedUser).id;
//         await api.put(`/notifications/mark-as-seen`, {
//           NotificationId: notification_id,
//           NhiUserId: userId
//         });
//         setNotifications((prev) => prev.filter((notif) => notif.notification_id !== notification_id));
//       }
//       navigate(url);
//     } catch (error) {
//       console.error('Error marking notification as read:', error);
//     }
//       console.log("🚀 ~ markAsRead ~ navigate:", navigate)
//   };

//   const notificationList = (
//     <div style={{ width: 320, maxHeight: 400, overflowY: 'auto' }}>
//       {notifications.length > 0 ? (
//         <List
//           itemLayout="horizontal"
//           dataSource={notifications}
//           renderItem={(notif) => (
//             <List.Item
//               actions={[
//                 <Button
//                   type="link"
//                   onClick={() => markAsRead(notif.notification_id, notif.redirect_to)}
//                   key="mark-read"
//                   style={{ color: '#A5805B' }}
//                 >
//                   Mark as read
//                 </Button>,
//               ]}
//             >
//               <List.Item.Meta
//                 title={<Text strong>{notif.contents}</Text>}
//                 description={<Text type="secondary">Just now</Text>}
//               />
//             </List.Item>
//           )}
//         />
//       ) : (
//         <Empty description="No new notifications" style={{ padding: 20 }} />
//       )}
//     </div>
//   );

//   return (
//     <NotificationsContainer>
//       <Popover
//         content={notificationList}
//         title="Notifications"
//         trigger="click"
//         visible={isPopoverOpen}
//         onVisibleChange={handlePopoverToggle}
//         placement="bottomRight"
//       >
//         <Badge count={notifications.length} overflowCount={9}>
//           <Tooltip title="Notifications">
//             <NotificationIcon />
//           </Tooltip>
//         </Badge>
//       </Popover>
//     </NotificationsContainer>
//   );
// }

// export default Notifications;

// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import { FiBell } from 'react-icons/fi';
// import axios from 'axios';
// import { constant } from '../../constant';
// import { Tooltip } from 'antd';
// import { useNavigate } from 'react-router-dom';

// const BASE_URL = `${constant.baseUrl}api`;

// const api = axios.create({
//   baseURL: BASE_URL,
// });

// const head = {
//   headers: {
//     Authorization: localStorage.getItem('token') || sessionStorage.getItem('token'),
//   },
// };

// // Styled components
// const NotificationsContainer = styled.div`
//   position: relative;
//   display: inline-block;
//   cursor: pointer;
// `;

// const BellIcon = styled(FiBell)`
//   font-size: 20px;
//   color: #000;
// `;

// const Badge = styled.span`
//   height: 25px;
//   width: 20px;
//   position: absolute;
//   left: 19px;
//   top: -5px;
//   right: -8px;
//   background-color: #ff4d4f;
//   color: white;
//   border-radius: 50%;
//   padding: 1px 1px;
//   font-size: 12px;
//   font-weight: bold;
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//   display: ${(props) => (props.count > 0 ? 'inline' : 'none')};
// `;

// const Dropdown = styled.div`
//   position: absolute;
//   top: 40px;
//   right: 0;
//   width: 320px;
//   max-height: 400px;
//   background-color: white;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   border-radius: 8px;
//   overflow-y: auto;
//   z-index: 1000;
//   display: ${(props) => (props.isOpen ? 'block' : 'none')};
//   animation: fadeIn 0.3s ease;

//   @keyframes fadeIn {
//     from { opacity: 0; }
//     to { opacity: 1; }
//   }
// `;

// const NotificationItem = styled.div`
//   padding: 15px;
//   border-bottom: 1px solid #f0f0f0;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   transition: background-color 0.2s;

//   &:hover {
//     background-color: #f0f0f0;
//   }
// `;

// const NotificationText = styled.div`
//   font-size: 14px;
//   color: #333;
//   margin-right: 10px;
//   flex: 1;
// `;

// const ReadButton = styled.button`
//   background-color: #A5805B;
//   color: white;
//   border: none;
//   border-radius: 4px;
//   padding: 5px 12px;
//   font-size: 12px;
//   cursor: pointer;
//   transition: background-color 0.2s;

//   &:hover {
//     background-color: #B5804B;
//   }
// `;

// const NoNotifications = styled.div`
//   padding: 20px;
//   text-align: center;
//   color: #999;
// `;

// function Notifications() {
//   const [notifications, setNotifications] = useState([]);
//   const [isOpen, setIsOpen] = useState(false);
//   const navigate = useNavigate();

//   // WebSocket setup to receive real-time notifications
//   useEffect(() => {
//     // Request notification permission on component mount
//     if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
//       Notification.requestPermission().then((permission) => {
//         if (permission === 'granted') {
//           console.log('Notification permission granted.');
//         }
//       });
//     }

//     const ws = new WebSocket(`${process.env.REACT_APP_BASE_URL_WS}`); // Adjust the WebSocket URL as needed

//     ws.onopen = () => {
//       console.log('WebSocket connection opened');
//     };

//     ws.onmessage = (event) => {
//       const newNotification = JSON.parse(event.data);

//       // Append the new notification to the state
//       setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);

//       // Play notification sound
//       const audio = new Audio('/notification_sound.wav');
//       audio.play().catch((error) => console.error("Failed to play sound:", error));

//       // Show browser notification if permission granted
//       if (Notification.permission === 'granted') {
//         new Notification('New Notification', {
//           body: newNotification.contents,
//           icon: '/notification_icon.png', // Optional: path to your notification icon
//         });
//       }
//     };

//     ws.onerror = (error) => {
//       console.error('WebSocket error:', error);
//     };

//     ws.onclose = () => {
//       console.log('WebSocket connection closed');
//     };

//     // Clean up WebSocket when the component unmounts
//     return () => {
//       ws.close();
//     };
//   }, []);

//   const fetchNotifications = async () => {
//     try {
//       const storedUser = localStorage.getItem('user');

//       if (storedUser) {
//           const user = JSON.parse(storedUser);
//           const userId = user.id;
//           console.log(userId); 

//           const response = await api.get(`/notifications/notifications/${userId}`);
//           setNotifications(response.data.data);
//       } else {
//           console.error('User not found in local storage');
//       }

//     } catch (error) {
//       console.error('Error fetching notifications:', error);
//     }
//   };

//   const handleDropdownToggle = () => {
//     if (!isOpen) fetchNotifications();
//     setIsOpen(!isOpen);
//   };

//   const markAsRead = async (notification_id, url) => {
//     try {
//       const storedUser = localStorage.getItem('user');
//       console.log('notification_id: ' + notification_id);
//       if (storedUser) {
//           const user = JSON.parse(storedUser);
//           const userId = user.id;
//           console.log(userId); 

//           const data = {
//             NotificationId: notification_id,
//             NhiUserId: userId
//         };
//           await api.put(`/notifications/mark-as-seen`, data);
          
//           setNotifications(notifications.filter((notif) => notif.notification_id !== notification_id));
//       } else {
//           console.error('User not found in local storage');
//       }
//       navigate(url);
//     } catch (error) {
//       console.error('Error marking notification as read:', error);
//     }
//   };

//   return (
//     <NotificationsContainer onClick={handleDropdownToggle}>
//       <Tooltip title="Notifications">
//         <BellIcon />
//         <Badge count={notifications.length}>{notifications.length}</Badge>
//       </Tooltip>
//       <Dropdown isOpen={isOpen}>
//         {notifications.length > 0 ? (
//           notifications.map((notif) => (
//             <NotificationItem key={notif.notification_id}>
//               <NotificationText>{notif.contents}</NotificationText>
//               <ReadButton onClick={() => markAsRead(notif.notification_id, notif.redirect_to)}>Read</ReadButton>
//             </NotificationItem>
//           ))
//         ) : (
//           <NoNotifications>No new notifications</NoNotifications>
//         )}
//       </Dropdown>
//     </NotificationsContainer>
//   );
// }

// export default Notifications;
// function Notifications() {
//   const [notifications, setNotifications] = useState([]);
//   const [isOpen, setIsOpen] = useState(false);

//   // WebSocket setup to receive real-time notifications
//   useEffect(() => {
//     if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
//       Notification.requestPermission().then((permission) => {
//         if (permission === 'granted') {
//           console.log('Notification permission granted.');
//         } else {
//           console.log('Notification permission denied.');
//         }
//       });
//     }
//     const ws = new WebSocket(`${process.env.REACT_APP_BASE_URL_WS}`); // Adjust the WebSocket URL as needed

//     ws.onopen = () => {
//       console.log('WebSocket connection opened');
//     };

//     ws.onmessage = (event) => {
//       const newNotification = JSON.parse(event.data);

//       // Append the new notification to the state
//       setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);

//       // Play notification sound
//       const audio = new Audio('/notification_sound.wav');
//       audio.play().catch((error) => console.error("Failed to play sound:", error));
//     };

//     ws.onerror = (error) => {
//       console.error('WebSocket error:', error);
//     };

//     ws.onclose = () => {
//       console.log('WebSocket connection closed');
//     };

//     // Clean up WebSocket when the component unmounts
//     return () => {
//       ws.close();
//     };
//   }, []);

//   const fetchNotifications = async () => {
//     try {
//       const response = await api.get('/notifications/notifications', head);
//       setNotifications(response.data.data);
//     } catch (error) {
//       console.error('Error fetching notifications:', error);
//     }
//   };

//   const handleDropdownToggle = () => {
//     fetchNotifications();
//     setIsOpen(!isOpen);
//   };

//   const markAsRead = async (id) => {
//     try {
//       await api.put(`/notifications/mark-as-seen/${id}`, head);
//       setNotifications(notifications.filter((notif) => notif.notification_id !== id));
//     } catch (error) {
//       console.error('Error marking notification as read:', error);
//     }
//   };

//   return (
//     <NotificationsContainer onClick={handleDropdownToggle}>
//       <Tooltip title="Notifications">
//         <BellIcon />
//         <Badge count={notifications.length}>{notifications.length}</Badge>
//       </Tooltip>
//       <Dropdown isOpen={isOpen}>
//         {notifications.length > 0 ? (
//           notifications.map((notif) => (
//             <NotificationItem key={notif.notification_id}>
//               <NotificationText>{notif.contents}</NotificationText>
//               <ReadButton onClick={() => markAsRead(notif.notification_id)}>Read</ReadButton>
//             </NotificationItem>
//           ))
//         ) : (
//           <NoNotifications>No new notifications</NoNotifications>
//         )}
//       </Dropdown>
//     </NotificationsContainer>
//   );
// }

// export default Notifications;


// import React, { useState, useEffect } from 'react';
// import styled from 'styled-components';
// import { FiBell } from 'react-icons/fi';
// import axios from 'axios';
// import { constant } from '../../constant';
// import { Tooltip } from 'antd';

// const BASE_URL = `${constant.baseUrl}api`;

// const api = axios.create({
//   baseURL: BASE_URL,
// });

// const head = {
//   headers: {
//     Authorization: localStorage.getItem('token') || sessionStorage.getItem('token'),
//   },
// };

// const NotificationsContainer = styled.div`
//   position: relative;
//   display: inline-block;
//   cursor: pointer;
// `;

// const BellIcon = styled(FiBell)`
//   font-size: 20px;  /* Larger size for modern look */
//   color: #000;  /* Modern blue color */
// `;

// const Badge = styled.span`
//   height: 25px;
//   width: 20px;
//   position: absolute;
//   left: 19px;
//   top: -5px;
//   right: -8px;
//   background-color: #ff4d4f;
//   color: white;
//   border-radius: 50%;
//   padding: 1px 1px;
//   font-size: 12px;
//   font-weight: bold;
//   display: inline;
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//   display: ${(props) => (props.count > 0 ? 'inline' : 'none')};
// `;

// const Dropdown = styled.div`
//   position: absolute;
//   top: 40px;
//   right: 0;
//   width: 320px;
//   max-height: 400px;
//   background-color: white;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   border-radius: 8px;
//   overflow-y: auto;
//   z-index: 1000;
//   display: ${(props) => (props.isOpen ? 'block' : 'none')};
//   animation: fadeIn 0.3s ease;  /* Smooth fade-in animation */
  
//   @keyframes fadeIn {
//     from { opacity: 0; }
//     to { opacity: 1; }
//   }
// `;

// const NotificationItem = styled.div`
//   padding: 15px;
//   border-bottom: 1px solid #f0f0f0;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   transition: background-color 0.2s;  /* Smooth transition on hover */

//   &:hover {
//     background-color: #f0f0f0;  /* Subtle hover effect */
//   }
// `;

// const NotificationText = styled.div`
//   font-size: 14px;
//   color: #333;
//   margin-right: 10px;
//   flex: 1;  /* Allow text to take up available space */
// `;

// const ReadButton = styled.button`
//   background-color: #A5805B;
//   color: white;
//   border: none;
//   border-radius: 4px;
//   padding: 5px 12px;  /* Adjusted padding for better touch target */
//   font-size: 12px;
//   cursor: pointer;
//   transition: background-color 0.2s;  /* Smooth transition on hover */

//   &:hover {
//     background-color: #B5804B;  /* Darker shade on hover */
//   }
// `;

// const NoNotifications = styled.div`
//   padding: 20px;
//   text-align: center;
//   color: #999;
// `;

// function Notifications() {
//   const [notifications, setNotifications] = useState([]);
//   const [isOpen, setIsOpen] = useState(false);

//   useEffect(() => {
//     fetchNotifications();
//   }, []);

//   const fetchNotifications = async () => {
//     try {
//       const response = await api.get('/notifications/notifications', head);
//       setNotifications(response.data.data);
//     } catch (error) {
//       console.error('Error fetching notifications:', error);
//     }
//   };

//   const handleDropdownToggle = () => {
//     fetchNotifications();
//     setIsOpen(!isOpen);
//   };

//   const markAsRead = async (id) => {
//     try {
//       await api.put(`/notifications/mark-as-seen/${id}`, head);
//       setNotifications(notifications.filter((notif) => notif.notification_id !== id));
//     } catch (error) {
//       console.error('Error marking notification as read:', error);
//     }
//   };

//   return (
//     <NotificationsContainer onClick={handleDropdownToggle}>
//       <Tooltip title="Notifications">
//         <BellIcon />
//         <Badge count={notifications.length}>{notifications.length}</Badge>
//       </Tooltip>
//       <Dropdown isOpen={isOpen}>
//         {notifications.length > 0 ? (
//           notifications.map((notif) => (
//             <NotificationItem key={notif.notification_id}>
//               <NotificationText>{notif.contents}</NotificationText>
//               <ReadButton onClick={() => markAsRead(notif.notification_id)}>Read</ReadButton>
//             </NotificationItem>
//           ))
//         ) : (
//           <NoNotifications>No new notifications</NoNotifications>
//         )}
//       </Dropdown>
//     </NotificationsContainer>
//   );
// }

// export default Notifications;
