import * as yup from 'yup';

export const CommissionerSchema = yup.object().shape({
    e_name: yup.string().required('Name is required'),
    email: yup.string().required('Email is required'),
    personalId: yup.string().required('Personal ID is required'),
    description: yup.string().required('Description is required')
});

// export default CommissionerSchema;
