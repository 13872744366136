import React from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Menu, Typography } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

const { Sider } = Layout;
const { Text } = Typography;

const NewSidebar = ({ routes, openSidebar, toggleSidebar }) => {
  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={!openSidebar}
      width={250}
      style={{
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        backgroundColor: '#ffffff',  // Ensure sidebar background is white
        overflowY: 'auto',
        boxShadow: '2px 0 8px rgba(0, 0, 0, 0.15)',  // Optional shadow for depth
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: openSidebar ? 'space-between' : 'center',
          padding: openSidebar ? '16px' : '16px 8px',
          color: '#333',  // Adjust icon color for visibility on white background
          cursor: 'pointer',
          borderBottom: '1px solid #f0f0f0',
        }}
        onClick={toggleSidebar}
      >
        {openSidebar ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        {openSidebar && (
          <Text
            style={{
              marginLeft: '8px',
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#333',  // Adjust text color for visibility on white background
            }}
          >
            My Application
          </Text>
        )}
      </div>
      
      <Menu
        mode="inline"
        defaultSelectedKeys={['0']}
        style={{
          backgroundColor: '#fff',  // Ensure menu background matches sidebar
          borderRight: 'none',
          paddingTop: '20px',
        }}
      >
        {routes.length === 0 ? (
          <Menu.Item disabled style={{ color: '#a0a0a0' }}>
            <Text>No available routes</Text>
          </Menu.Item>
        ) : (
          routes
            .filter(route => route.icon)
            .map((route, key) => (
              <Menu.Item key={key} icon={route.icon}>
                <NavLink
                  to={route.path}
                  style={{
                    color: '#333',  // Set NavLink color for readability
                    textDecoration: 'none',
                  }}
                  activeStyle={{
                    fontWeight: 'bold',
                    color: '#a5805b',
                  }}
                >
                  {openSidebar && route.name}
                </NavLink>
              </Menu.Item>
            ))
        )}
      </Menu>
    </Sider>
  );
};

export default NewSidebar;

// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { Layout, Menu, Typography } from 'antd';
// import {
//   MenuUnfoldOutlined,
//   MenuFoldOutlined,
// } from '@ant-design/icons';

// const { Sider } = Layout;
// const { Text } = Typography;

// const NewSidebar = ({ routes, openSidebar, toggleSidebar }) => {
//   return (
//     <Sider
//       trigger={null}
//       collapsible
//       collapsed={!openSidebar}
//       width={250}
//       style={{
//         height: '100vh',
//         position: 'fixed',
//         left: 0,
//         top: 0,
//         backgroundColor: '#fff !important',
//         overflowY: 'auto',
//       }}
//     >
//       <div
//         style={{
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: openSidebar ? 'space-between' : 'center',
//           padding: openSidebar ? '16px' : '16px 8px',
//           color: '#fff',
//           cursor: 'pointer',
//           borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
//         }}
//         onClick={toggleSidebar}
//       >
//         {openSidebar ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
//         {openSidebar && (
//           <Text
//             style={{
//               marginLeft: '8px',
//               fontSize: '16px',
//               fontWeight: 'bold',
//             }}
//           >
//             My Application
//           </Text>
//         )}
//       </div>
      
//       <Menu
//         theme="dark"
//         mode="inline"
//         defaultSelectedKeys={['0']}
//         style={{
//           borderRight: 'none',
//           paddingTop: '20px',
//         }}
//       >
//         {routes.length === 0 ? (
//           <Menu.Item disabled style={{ color: '#a0a0a0' }}>
//             <Text>No available routes</Text>
//           </Menu.Item>
//         ) : (
//           routes
//             .filter(route => route.icon)
//             .map((route, key) => (
//               <Menu.Item key={key} icon={route.icon}>
//                 <NavLink
//                   to={route.path}
//                   style={{
//                     color: 'inherit',
//                     textDecoration: 'none',  // Remove underline from NavLink
//                   }}
//                   activeStyle={{
//                     fontWeight: 'bold',
//                     color: '#a5805b !important',
//                   }}
//                 >
//                   {openSidebar && route.name}
//                 </NavLink>
//               </Menu.Item>
//             ))
//         )}
//       </Menu>
//     </Sider>
//   );
// };

// export default NewSidebar;

// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { Box, Flex, Text, VStack } from '@chakra-ui/react';

// const NewSidebar = ({ routes, openSidebar }) => {
//   return (
//     <Box
//       as="nav"
//       pos="fixed"
//       left={0}
//       top={0}
//       w={openSidebar ? '250px' : '88px'}
//       h="100vh"
//       bg="gray.800"
//       color="white"
//       transition="width 0.2s ease"
//       overflowY="auto" // Enable vertical scrolling
//       css={{
//         '&::-webkit-scrollbar': {
//           width: '6px',
//         },
//         '&::-webkit-scrollbar-thumb': {
//           background: 'gray.600',
//           borderRadius: '24px',
//         },
//       }}
//     >
//       <VStack spacing={4} align="start" p={4}>
//         {routes.length === 0 ? (
//           <Text color="gray.400">No available routes</Text>
//         ) : (
//           routes
//             .filter(route => route.icon)  // Filter out routes without an icon
//             .map((route, key) => (
//               <NavLink to={route.path} key={key} style={{ width: '100%' }}>
//                 <Flex
//                   align="center"
//                   p={3}
//                   borderRadius="md"
//                   _hover={{ bg: 'gray.700' }}
//                   transition="background-color 0.2s ease"
//                 >
//                   {/* Render the icon from the route configuration */}
//                   <Box mr={openSidebar ? 3 : 0}>
//                     {route.icon}
//                   </Box>
//                   {/* Route name, only shown when sidebar is open */}
//                   {openSidebar && (
//                     <Text fontSize="md" fontWeight="medium">
//                       {route.name}
//                     </Text>
//                   )}
//                 </Flex>
//               </NavLink>
//             ))
//         )}
//       </VStack>
//     </Box>
//   );
// };

// export default NewSidebar;

// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { Box, Flex, Text, VStack } from '@chakra-ui/react';

// const NewSidebar = ({ routes, openSidebar }) => {
//   return (
//     <Box
//       as="nav"
//       pos="fixed"
//       left={0}
//       top={0}
//       w={openSidebar ? '250px' : '88px'}
//       h="100vh"
//       bg="gray.800"
//       color="white"
//       transition="width 0.2s ease"
//       overflowY="auto" // Enable vertical scrolling
//       css={{
//         '&::-webkit-scrollbar': {
//           width: '6px',
//         },
//         '&::-webkit-scrollbar-thumb': {
//           background: 'gray.600',
//           borderRadius: '24px',
//         },
//       }}
//     >
//       <VStack spacing={4} align="start" p={4}>
//         {routes.length === 0 ? (
//           <Text color="gray.400">No available routes</Text>
//         ) : (
//           routes.map((route, key) => (
//             <NavLink to={route.path} key={key} style={{ width: '100%' }}>
//               <Flex
//                 align="center"
//                 p={3}
//                 borderRadius="md"
//                 _hover={{ bg: 'gray.700' }}
//                 transition="background-color 0.2s ease"
//               >
//                 {/* Render the icon from the route configuration */}
//                 {route.icon && (
//                   <Box mr={openSidebar ? 3 : 0}>
//                     {route.icon}
//                   </Box>
//                 )}
//                 {/* Route name, only shown when sidebar is open */}
//                 {openSidebar && (
//                   <Text fontSize="md" fontWeight="medium">
//                     {route.name}
//                   </Text>
//                 )}
//               </Flex>
//             </NavLink>
//           ))
//         )}
//       </VStack>
//     </Box>
//   );
// };

// export default NewSidebar;

// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { Box, Flex, Text, VStack } from '@chakra-ui/react';

// const NewSidebar = ({ routes, openSidebar }) => {
//   return (
//     <Box
//       as="nav"
//       pos="fixed"
//       left={0}
//       top={0}
//       w={openSidebar ? '250px' : '88px'}
//       h="100vh"
//       bg="gray.800"
//       color="white"
//       transition="width 0.2s ease"
//       overflowY="auto" // Enable vertical scrolling
//       css={{
//         '&::-webkit-scrollbar': {
//           width: '6px',
//         },
//         '&::-webkit-scrollbar-thumb': {
//           background: 'gray.600',
//           borderRadius: '24px',
//         },
//       }}
//     >
//       <VStack spacing={4} align="start" p={4}>
//         {routes.map((route, key) => (
//           <NavLink to={route.path} key={key} style={{ width: '100%' }}>
//             <Flex
//               align="center"
//               p={3}
//               borderRadius="md"
//               _hover={{ bg: 'gray.700' }}
//               transition="background-color 0.2s ease"
//             >
//               {/* Render the icon from the route configuration */}
//               {route.icon && (
//                 <Box mr={openSidebar ? 3 : 0}>
//                   {route.icon}
//                 </Box>
//               )}
//               {/* Route name, only shown when sidebar is open */}
//               {openSidebar && (
//                 <Text fontSize="md" fontWeight="medium">
//                   {route.name}
//                 </Text>
//               )}
//             </Flex>
//           </NavLink>
//         ))}
//       </VStack>
//     </Box>
//   );
// };

// export default NewSidebar;
