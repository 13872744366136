// src/schema/validationSchemas.js
import * as yup from 'yup';

export const TicketSchema = yup.object().shape({
    type: yup.string().required('Type is required'),
    customer: yup.string().required('Customer is required'),
    project: yup.string().required('Project is required'),
    apartmentNo: yup.string().required('Apartment No. is required'),
    service: yup.string().required('Service is required'),
    category: yup.string().required('Category is required'),
    subCategory: yup.string().required('Sub Category is required'),
    assignedTo: yup.string().required('Assigned To is required'),
    taskOwner: yup.string().required('Task Owner is required'),
    caller: yup.string().required('Caller is required'),
    salesProject: yup.string().required('Sales Project is required'),
    description: yup.string().required('Description is required'),
});

export default TicketSchema;
