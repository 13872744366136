import React, { Suspense, useEffect, useState } from 'react';
import { Layout, Typography, Spin } from 'antd';
import Footer from 'components/footer/FooterAdmin';
import Navbar from 'components/navbar/NavbarAdmin';
import NewSidebar from './newSidebar'; 
import { useDispatch, useSelector } from 'react-redux';
import { fetchImage } from '../../redux/imageSlice';
import { getApi } from 'services/api';
import { Route, Routes } from 'react-router-dom';
import routes from '../../routes';

const { Content, Header } = Layout;
const { Text } = Typography;

export default function User(props) {
  const { ...rest } = props;
  const [route, setRoute] = useState([]);
  const [loadingRoutes, setLoadingRoutes] = useState(true);
  const [openSidebar, setOpenSidebar] = useState(true);
  const user = JSON.parse(localStorage.getItem('user'));

  const dispatch = useDispatch();

  const fetchRoutesAndAccess = async () => {
    try {
      const response = await getApi(`api/route/${user.id}/routes-access`);
      const userAccessData = response.data.access || [];
  
      const accessibleRouteNames = userAccessData
        .filter((access) => access.view)
        .map((access) => access.views);
  
      const filterRoutes = (allRoutes, allowedRoutes) => {
        return allRoutes.reduce((acc, route) => {
          if (allowedRoutes.includes(route.name) || (route.parentName && allowedRoutes.includes(route.parentName))) {
            const filteredRoute = { ...route };
  
            if (filteredRoute.items) {
              filteredRoute.items = filterRoutes(filteredRoute.items, allowedRoutes);
            }
  
            acc.push(filteredRoute);
          }
          return acc;
        }, []);
      };
  
      const filteredRoutes = filterRoutes(routes, accessibleRouteNames);
      setRoute(filteredRoutes);
    } catch (error) {
      console.error("Error fetching routes and access:", error);
    } finally {
      setLoadingRoutes(false);
    }
  };

  useEffect(() => {
    fetchRoutesAndAccess();
  }, []);

  const getRoutes = (routes) => {
    const renderRoutes = (routeList) => {
      return routeList.flatMap((route, key) => {
        if (route.items && route.items.length > 0) {
          return renderRoutes(route.items);
        }
        return (
          <Route path={route.path} element={<route.component />} key={key} />
        );
      });
    };

    return (
      <Routes>
        {renderRoutes(routes)}
      </Routes>
    );
  };

  const getActiveRoute = (routes) => {
    let activeRoute = 'Dashboard';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].items) {
        let nestedActiveRoute = getActiveRoute(routes[i].items);
        if (nestedActiveRoute !== activeRoute) {
          return nestedActiveRoute;
        }
      } else if (window.location.href.includes(routes[i].path)) {
        return routes[i].name;
      }
    }
    return activeRoute;
  };

  useEffect(() => {
    dispatch(fetchImage());
  }, [dispatch]);

  const largeLogo = useSelector((state) =>
    state?.images?.images?.filter((item) => item?.isActive === true)
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <NewSidebar routes={route} openSidebar={openSidebar} toggleSidebar={() => setOpenSidebar(!openSidebar)} />
      <Layout style={{ marginLeft: openSidebar ? 250 : 88, transition: 'margin-left 0.3s ease' }}>
        <Header style={{ backgroundColor: '#fff', padding: '0 24px', display: 'flex', alignItems: 'center' }}>
          <Navbar
            logoText={'Your Dashboard'}
            brandText={getActiveRoute(route)}
            routes={route}
            largeLogo={largeLogo}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
            {...rest}
          />
        </Header>

        <Content style={{ padding: '24px', backgroundColor: '#f0f2f5' }}>
          <Suspense
            fallback={
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '84vh' }}>
                <Spin size="large" />
              </div>
            }
          >
            {loadingRoutes ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '84vh' }}>
                <Spin size="large" />
              </div>
            ) : (
              getRoutes(route)
            )}
          </Suspense>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

// import React, { Suspense, useEffect, useState } from 'react';
// import {
//   Box,
//   useDisclosure,
//   Portal,
//   Flex,
//   Text
// } from '@chakra-ui/react';
// import Footer from 'components/footer/FooterAdmin.js';
// import Navbar from 'components/navbar/NavbarAdmin.js';
// import NewSidebar from './newSidebar'; 
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchImage } from '../../redux/imageSlice';
// import { getApi } from 'services/api';
// import { Route, Routes } from 'react-router-dom';
// import Spinner from 'components/spinner/Spinner';

// import routes from '../../routes';

// export default function User(props) {
//   const { ...rest } = props;
//   const [route, setRoute] = useState([]);
//   const [loadingRoutes, setLoadingRoutes] = useState(true);
//   const [openSidebar, setOpenSidebar] = useState(true);
//   const user = JSON.parse(localStorage.getItem('user'));

//   const dispatch = useDispatch();

//   const fetchRoutesAndAccess = async () => {
//     try {
//       const response = await getApi(`api/route/${user.id}/routes-access`);
//       const userAccessData = response.data.access || [];
//       console.log("🚀 ~ fetchRoutesAndAccess ~ userAccessData:", userAccessData)
  
//       const accessibleRouteNames = userAccessData
//         .filter((access) => access.view)
//         .map((access) => access.views);
  
//       const filterRoutes = (allRoutes, allowedRoutes) => {
//         return allRoutes.reduce((acc, route) => {
//           if (allowedRoutes.includes(route.name) || (route.parentName && allowedRoutes.includes(route.parentName))) {
//             const filteredRoute = { ...route };
  
//             if (filteredRoute.items) {
//               filteredRoute.items = filterRoutes(filteredRoute.items, allowedRoutes);
//             }
  
//             acc.push(filteredRoute);
//           }
//           return acc;
//         }, []);
//       };
  
//       const filteredRoutes = filterRoutes(routes, accessibleRouteNames);
//       setRoute(filteredRoutes);
//     } catch (error) {
//       console.error("Error fetching routes and access:", error);
//     } finally {
//       setLoadingRoutes(false);
//     }
//   };
  
//   useEffect(() => {
//     fetchRoutesAndAccess();
//   }, []);

//   const getRoutes = (routes) => {
//     const renderRoutes = (routeList) => {
//       return routeList.flatMap((route, key) => {
//         if (route.items && route.items.length > 0) {
//           return renderRoutes(route.items);
//         }
//         return (
//           <Route path={route.path} element={<route.component />} key={key} />
//         );
//       });
//     };

//     return (
//       <Routes>
//         {renderRoutes(routes)}
//       </Routes>
//     );
//   };

//   const getActiveRoute = (routes) => {
//     let activeRoute = 'Dashboard';
//     for (let i = 0; i < routes.length; i++) {
//       if (routes[i].items) {
//         let nestedActiveRoute = getActiveRoute(routes[i].items);
//         if (nestedActiveRoute !== activeRoute) {
//           return nestedActiveRoute;
//         }
//       } else if (window.location.href.includes(routes[i].path)) {
//         return routes[i].name;
//       }
//     }
//     return activeRoute;
//   };

//   const { onOpen } = useDisclosure();

//   useEffect(() => {
//     dispatch(fetchImage());
//   }, [dispatch]);

//   const largeLogo = useSelector((state) =>
//     state?.images?.images?.filter((item) => item?.isActive === true)
//   );

//   return (
//     <Box>
//       <Box>
//         <NewSidebar routes={route} openSidebar={openSidebar} />
//         <Box
//           float="right"
//           minHeight="100vh"
//           height="100%"
//           overflow="auto"
//           position="relative"
//           maxHeight="100%"
//           w={{ base: '100%', xl: openSidebar ? 'calc( 100% - 300px )' : 'calc( 100% - 88px )' }}
//           maxWidth={{ base: '100%', xl: openSidebar ? 'calc( 100% - 300px )' : 'calc( 100% - 88px )' }}
//           transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
//         >
//           <Portal>
//             <Box className="header">
//               <Navbar
//                 onOpen={onOpen}
//                 logoText={'Your Dashboard'}
//                 brandText={getActiveRoute(route)}
//                 fixed={false}
//                 routes={route}
//                 largeLogo={largeLogo}
//                 openSidebar={openSidebar}
//                 setOpenSidebar={setOpenSidebar}
//                 {...rest}
//               />
//             </Box>
//           </Portal>

//           <Box pt={{ base: '150px', md: '95px', xl: '95px' }}>
//             <Box mx="auto" pe="20px" minH="84vh" pt="50px">
//               <Suspense
//                 fallback={
//                   <Flex justifyContent="center" alignItems="center" width="100%">
//                     <Spinner />
//                   </Flex>
//                 }
//               >
//                 {loadingRoutes ? (
//                   <Flex justifyContent="center" alignItems="center" minH="84vh">
//                     <Spinner />
//                     {/* <Text ml={4}>Loading routes...</Text> */}
//                   </Flex>
//                 ) : (
//                   getRoutes(route)
//                 )}
//               </Suspense>
//             </Box>
//           </Box>

//           <Box>
//             <Footer />
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// }
// import React, { Suspense, useEffect, useState } from 'react';
// import {
//   Box,
//   useDisclosure,
//   Portal,
//   Flex
// } from '@chakra-ui/react';
// import Footer from 'components/footer/FooterAdmin.js';
// import Navbar from 'components/navbar/NavbarAdmin.js';
// import NewSidebar from './newSidebar'; 
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchImage } from '../../redux/imageSlice';
// import { getApi } from 'services/api';
// import { Navigate, Route, Routes } from 'react-router-dom';
// import Spinner from 'components/spinner/Spinner';

// import routes from '../../routes';

// export default function User(props) {
//   const { ...rest } = props;
//   const [route, setRoute] = useState([]);
//   const [openSidebar, setOpenSidebar] = useState(true);
//   const user = JSON.parse(localStorage.getItem('user'));

//   const dispatch = useDispatch();

//   const fetchRoutesAndAccess = async () => {
//     try {
//       const response = await getApi(`api/route/${user.id}/routes-access`);
//       const userAccessData = response.data.access || [];
  
//       const accessibleRouteNames = userAccessData
//         .filter((access) => access.view)
//         .map((access) => access.views);
  
//       // Recursively filter routes based on accessibleRouteNames, accounting for nested structures
//       const filterRoutes = (allRoutes, allowedRoutes) => {
//         return allRoutes.reduce((acc, route) => {
//           if (allowedRoutes.includes(route.name) || (route.parentName && allowedRoutes.includes(route.parentName))) {
//             const filteredRoute = { ...route };
  
//             // Recursively filter nested items
//             if (filteredRoute.items) {
//               filteredRoute.items = filterRoutes(filteredRoute.items, allowedRoutes);
//             }
  
//             acc.push(filteredRoute);
//           }
//           return acc;
//         }, []);
//       };
  
//       const filteredRoutes = filterRoutes(routes, accessibleRouteNames);
//       setRoute(filteredRoutes);
//     } catch (error) {
//       console.error("Error fetching routes and access:", error);
//     }
//   };
  
//   useEffect(() => {
//     fetchRoutesAndAccess();
//   }, []);

//   const getRoutes = (routes) => {
//     const renderRoutes = (routeList) => {
//       return routeList.flatMap((route, key) => {
//         if (route.items && route.items.length > 0) {
//           return renderRoutes(route.items);
//         }
//         return (
//           <Route path={route.path} element={<route.component />} key={key} />
//         );
//       });
//     };

//     return (
//       <Routes>
//         {renderRoutes(routes)}
//         <Route path="/*" element={<Navigate to="/default" />} />
//       </Routes>
//     );
//   };

//   const getActiveRoute = (routes) => {
//     let activeRoute = 'Dashboard';
//     for (let i = 0; i < routes.length; i++) {
//       if (routes[i].items) {
//         let nestedActiveRoute = getActiveRoute(routes[i].items);
//         if (nestedActiveRoute !== activeRoute) {
//           return nestedActiveRoute;
//         }
//       } else if (window.location.href.includes(routes[i].path)) {
//         return routes[i].name;
//       }
//     }
//     return activeRoute;
//   };

//   const { onOpen } = useDisclosure();

//   useEffect(() => {
//     dispatch(fetchImage());
//   }, [dispatch]);

//   const largeLogo = useSelector((state) =>
//     state?.images?.images?.filter((item) => item?.isActive === true)
//   );

//   return (
//     <Box>
//       <Box>
//         <NewSidebar routes={route} openSidebar={openSidebar} />
//         <Box
//           float="right"
//           minHeight="100vh"
//           height="100%"
//           overflow="auto"
//           position="relative"
//           maxHeight="100%"
//           w={{ base: '100%', xl: openSidebar ? 'calc( 100% - 300px )' : 'calc( 100% - 88px )' }}
//           maxWidth={{ base: '100%', xl: openSidebar ? 'calc( 100% - 300px )' : 'calc( 100% - 88px )' }}
//           transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
//         >
//           <Portal>
//             <Box className="header">
//               <Navbar
//                 onOpen={onOpen}
//                 logoText={'Your Dashboard'}
//                 brandText={getActiveRoute(route)}
//                 fixed={false}
//                 routes={route}
//                 largeLogo={largeLogo}
//                 openSidebar={openSidebar}
//                 setOpenSidebar={setOpenSidebar}
//                 {...rest}
//               />
//             </Box>
//           </Portal>

//           <Box pt={{ base: '150px', md: '95px', xl: '95px' }}>
//             <Box mx="auto" pe="20px" minH="84vh" pt="50px">
//               <Suspense
//                 fallback={
//                   <Flex justifyContent="center" alignItems="center" width="100%">
//                     <Spinner />
//                   </Flex>
//                 }
//               >
//                 {getRoutes(route)}
//               </Suspense>
//             </Box>
//           </Box>

//           <Box>
//             <Footer />
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

// import React, { Suspense, useEffect, useState } from 'react';
// import {
//   Box,
//   useDisclosure,
//   Portal,
//   Flex
// } from '@chakra-ui/react';
// import Footer from 'components/footer/FooterAdmin.js';
// import Navbar from 'components/navbar/NavbarAdmin.js';
// import NewSidebar from './newSidebar'; // Import the new sidebar
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchImage } from '../../redux/imageSlice';
// import { getApi } from 'services/api';
// import { Navigate, Route, Routes } from 'react-router-dom';
// import Spinner from 'components/spinner/Spinner';

// import routes from '../../routes'; // Assuming routes.js is the file where all routes are defined

// export default function User(props) {
//   const { ...rest } = props;
//   const [route, setRoute] = useState([]);
//   const [openSidebar, setOpenSidebar] = useState(true);
//   const user = JSON.parse(localStorage.getItem('user'));

//   const dispatch = useDispatch();

//   // const fetchRoutesAndAccess = async () => {
//   //   try {
//   //     const response = await getApi(`api/route/${user.id}/routes-access`);
//   //     const userAccessData = response.data.access;
//   //     console.log("🚀 ~ fetchRoutesAndAccess ~ userAccessData:", userAccessData)

//   //     const userRoutes = userAccessData
//   //     .filter((access) => access.view)  // Only include accesses where view is true
//   //     .map((access) => access.views);
//   //     console.log("🚀 ~ fetchRoutesAndAccess ~ userRoutes:", userRoutes)

//   //     // Filter only parent routes (those without parentName or under)
//   //     const filteredRoutes = routes.filter(
//   //       (route) => userRoutes.includes(route.name) && !route.parentName && !route.under
//   //     );
//   //     console.log("🚀 ~ fetchRoutesAndAccess ~ filteredRoutes:", filteredRoutes)

//   //     setRoute(filteredRoutes);
//   //   } catch (error) {
//   //     console.error('Error fetching routes and access:', error);
//   //   }
//   // };
//   const fetchRoutesAndAccess = async () => {
//     try {
//       const response = await getApi(`api/route/${user.id}/routes-access`);
//       const userAccessData = response.data.access;
  
//       // Collect all viewable route names
//       const userRoutes = userAccessData
//         .filter((access) => access.view)
//         .map((access) => access.views);
  
//       // Function to recursively filter routes
//       const filterRoutes = (allRoutes, allowedRoutes) => {
//         return allRoutes.reduce((acc, route) => {
//           // Check if the route or its parent is allowed
//           if (allowedRoutes.includes(route.name) || (route.parentName && allowedRoutes.includes(route.parentName))) {
//             const filteredRoute = { ...route };
  
//             // If the route has children, filter them recursively
//             if (filteredRoute.items) {
//               filteredRoute.items = filterRoutes(filteredRoute.items, allowedRoutes);
//             }
  
//             acc.push(filteredRoute);
//           }
//           return acc;
//         }, []);
//       };
  
//       const filteredRoutes = filterRoutes(routes, userRoutes);
  
//       setRoute(filteredRoutes);
//     } catch (error) {
//       console.error("Error fetching routes and access:", error);
//     }
//   };
  
  
//   useEffect(() => {
//     fetchRoutesAndAccess();
//   }, []);

//   const getRoutes = (routes) => {
//     const renderRoutes = (routeList) => {
//       return routeList.map((route, key) => {
//         if (route.items && route.items.length > 0) {
//           // Recursively render child routes
//           return renderRoutes(route.items);
//         }
//         return (
//           <Route path={route.path} element={<route.component />} key={key} />
//         );
//       });
//     };
  
//     return (
//       <Routes>
//         {renderRoutes(routes)}
//         {/* Catch-all route */}
//         <Route path="/*" element={<Navigate to="/default" />} />
//       </Routes>
//     );
//   };
  
  

//   const getActiveRoute = (routes) => {
//     let activeRoute = 'Dashboard';
//     for (let i = 0; i < routes.length; i++) {
//       if (routes[i].collapse) {
//         let collapseActiveRoute = getActiveRoute(routes[i].items);
//         if (collapseActiveRoute !== activeRoute) {
//           return collapseActiveRoute;
//         }
//       } else if (window.location.href.indexOf(routes[i].path) !== -1) {
//         return routes[i].name;
//       }
//     }
//     return activeRoute;
//   };

//   const { onOpen } = useDisclosure();

//   useEffect(() => {
//     dispatch(fetchImage());
//   }, [dispatch]);

//   const largeLogo = useSelector((state) =>
//     state?.images?.images?.filter((item) => item?.isActive === true)
//   );

//   return (
//     <Box>
//       <Box>
//         <NewSidebar routes={route} openSidebar={openSidebar} />
//         <Box
//           float="right"
//           minHeight="100vh"
//           height="100%"
//           overflow="auto"
//           position="relative"
//           maxHeight="100%"
//           w={{ base: '100%', xl: openSidebar ? 'calc( 100% - 300px )' : 'calc( 100% - 88px )' }}
//           maxWidth={{ base: '100%', xl: openSidebar ? 'calc( 100% - 300px )' : 'calc( 100% - 88px )' }}
//           transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
//         >
//           <Portal>
//             <Box className="header">
//               <Navbar
//                 onOpen={onOpen}
//                 logoText={'Your Dashboard'}
//                 brandText={getActiveRoute(route)}
//                 fixed={false}
//                 routes={route}
//                 largeLogo={largeLogo}
//                 openSidebar={openSidebar}
//                 setOpenSidebar={setOpenSidebar}
//                 {...rest}
//               />
//             </Box>
//           </Portal>

//           <Box pt={{ base: '150px', md: '95px', xl: '95px' }}>
//             <Box mx="auto" pe="20px" minH="84vh" pt="50px">
//               <Suspense
//                 fallback={
//                   <Flex justifyContent="center" alignItems="center" width="100%">
//                     <Spinner />
//                   </Flex>
//                 }
//               >
//                 {getRoutes(route)}
//               </Suspense>
//             </Box>
//           </Box>

//           <Box>
//             <Footer />
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

// import React, { Suspense,useEffect, useState } from 'react';
// import {
//   Box,
//   useDisclosure,
//   Portal,
//   Flex
// } from '@chakra-ui/react';
// import Footer from 'components/footer/FooterAdmin.js';
// import Navbar from 'components/navbar/NavbarAdmin.js';
// import NewSidebar from './newSidebar'; // Import the new sidebar
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchImage } from '../../redux/imageSlice';
// import { getApi } from 'services/api';
// import { Navigate, Route, Routes } from 'react-router-dom';
// import Spinner from 'components/spinner/Spinner';
//   import Sidebar from 'components/sidebar/Sidebar.js';

// import routes from '../../routes'; // Assuming routes.js is the file where all routes are defined

// export default function User(props) {
//   const { ...rest } = props;
//   const [route, setRoute] = useState([]);
//   const [openSidebar, setOpenSidebar] = useState(true);
//   const user = JSON.parse(localStorage.getItem('user'));

//   const dispatch = useDispatch();

//   const fetchRoutesAndAccess = async () => {
//     try {
//       const response = await getApi(`api/route/${user.id}/routes-access`);
//       const userAccessData = response.data.access;
//       const userRoutes = userAccessData.map((access) => access.views);

//       const filteredRoutes = routes.filter((route) =>
//         userRoutes.includes(route.name)
//       );

//       setRoute(filteredRoutes);
//     } catch (error) {
//       console.error('Error fetching routes and access:', error);
//     }
//   };

//   useEffect(() => {
//     fetchRoutesAndAccess();
//   }, []);

//   const getRoutes = (routes) => {
//     return (
//       <Routes>
//         {routes.map((prop, key) => (
//           <Route path={prop.path} element={<prop.component />} key={key} />
//         ))}
//         {/* Fallback route if no match is found */}
//         <Route path="/*" element={<Navigate to="/default" />} />
//       </Routes>
//     );
//   };

//   const getActiveRoute = (routes) => {
//     let activeRoute = 'Dashboard';
//     for (let i = 0; i < routes.length; i++) {
//       if (routes[i].collapse) {
//         let collapseActiveRoute = getActiveRoute(routes[i].items);
//         if (collapseActiveRoute !== activeRoute) {
//           return collapseActiveRoute;
//         }
//       } else if (window.location.href.indexOf(routes[i].path) !== -1) {
//         return routes[i].name;
//       }
//     }
//     return activeRoute;
//   };

//   const { onOpen } = useDisclosure();

//   useEffect(() => {
//     dispatch(fetchImage());
//   }, [dispatch]);

//   const largeLogo = useSelector((state) =>
//     state?.images?.images?.filter((item) => item?.isActive === true)
//   );

//   return (
//     <Box>
//       <Box>
//         <NewSidebar routes={route} openSidebar={openSidebar} />
//         <Box
//           float="right"
//           minHeight="100vh"
//           height="100%"
//           overflow="auto"
//           position="relative"
//           maxHeight="100%"
//           w={{ base: '100%', xl: openSidebar ? 'calc( 100% - 300px )' : 'calc( 100% - 88px )' }}
//           maxWidth={{ base: '100%', xl: openSidebar ? 'calc( 100% - 300px )' : 'calc( 100% - 88px )' }}
//           transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
//         >
//           <Portal>
//             <Box className="header">
//               <Navbar
//                 onOpen={onOpen}
//                 logoText={'Your Dashboard'}
//                 brandText={getActiveRoute(route)}
//                 fixed={false}
//                 routes={route}
//                 largeLogo={largeLogo}
//                 openSidebar={openSidebar}
//                 setOpenSidebar={setOpenSidebar}
//                 {...rest}
//               />
//             </Box>
//           </Portal>

//           <Box pt={{ base: '150px', md: '95px', xl: '95px' }}>
//             <Box mx="auto" pe="20px" minH="84vh" pt="50px">
//               <Suspense
//                 fallback={
//                   <Flex justifyContent="center" alignItems="center" width="100%">
//                     <Spinner />
//                   </Flex>
//                 }
//               >
//                 {getRoutes(route)}
//               </Suspense>
//             </Box>
//           </Box>

//           <Box>
//             <Footer />
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

// import {
//     Portal,
//     Box,
//     useDisclosure,
//     Flex,
//   } from '@chakra-ui/react';
//   import Footer from 'components/footer/FooterAdmin.js';
//   import Navbar from 'components/navbar/NavbarAdmin.js';
//   import Sidebar from 'components/sidebar/Sidebar.js';
//   import { SidebarContext } from 'contexts/SidebarContext';
//   import React, { Suspense, useEffect, useState } from 'react';
//   import { Navigate, Route, Routes } from 'react-router-dom';
//   import Spinner from 'components/spinner/Spinner';
//   import { useDispatch, useSelector } from 'react-redux';
//   import { fetchImage } from '../../redux/imageSlice';
//   import { getApi } from 'services/api';
//   import routes from '../../routes.js';

  
//   export default function User(props) {
//     const { ...rest } = props;
//     const [fixed] = useState(false);
//     const [toggleSidebar, setToggleSidebar] = useState(false);
//     const [route, setRoute] = useState([]);
//     const [openSidebar, setOpenSidebar] = useState(true);
//     const user = JSON.parse(localStorage.getItem("user"));
    
//     const dispatch = useDispatch();
    
//     const fetchRoutesAndAccess = async () => {
//         try {
//           const response = await getApi(`api/route/${user.id}/routes-access`);
//           const userAccessData = response.data.access; // Access data from API
//           const userRoutes = userAccessData.map(access => access.views);
      
//           // Filter routes based on user's access
//           const filteredRoutes = routes.filter(route =>
//             userRoutes.includes(route.name)
//           );
      
//           setRoute(filteredRoutes);
//         } catch (error) {
//           console.error("Error fetching routes and access:", error);
//         }
//       };
      
  
//     useEffect(() => {
//       fetchRoutesAndAccess();
//     }, []);
  
//     const pathName = (name) => `/${name.toLowerCase().replace(/ /g, '-')}`;
    
//     const getRoutes = (routes) => {
//         return routes.map((prop, key) => (
//           <Route path={prop.path} element={<prop.component />} key={key} />
//         ));
//       };
        
//     // const getRoutes = (routes) => {
//     //   return routes.map((prop, key) => {
//     //     if (!prop.under && prop.layout !== '/auth') {
//     //       return <Route path={prop.path} element={<prop.component />} key={key} />;
//     //     } else if (prop.under) {
//     //       return <Route path={prop.path} element={<prop.component />} key={key} />;
//     //     }
//     //     if (prop.collapse) {
//     //       return getRoutes(prop.items);
//     //     }
//     //     if (prop.category) {
//     //       return getRoutes(prop.items);
//     //     }
//     //     return null;
//     //   });
//     // };
  
//     const getActiveRoute = (routes) => {
//       let activeRoute = 'Prolink';
//       for (let i = 0; i < routes.length; i++) {
//         if (routes[i].collapse) {
//           let collapseActiveRoute = getActiveRoute(routes[i].items);
//           if (collapseActiveRoute !== activeRoute) {
//             return collapseActiveRoute;
//           }
//         } else if (routes[i].category) {
//           let categoryActiveRoute = getActiveRoute(routes[i].items);
//           if (categoryActiveRoute !== activeRoute) {
//             return categoryActiveRoute;
//           }
//         } else {
//           if (window.location.href.indexOf(routes[i].path.replace("/:id", "")) !== -1) {
//             return routes[i].name;
//           }
//         }
//       }
//       return activeRoute;
//     };
  
//     document.documentElement.dir = 'ltr';
//     const { onOpen } = useDisclosure();
  
//     useEffect(() => {
//       dispatch(fetchImage());
//     }, [dispatch]);
  
//     const largeLogo = useSelector((state) => state?.images?.images?.filter(item => item?.isActive === true));
  
//     return (
//       <Box>
//         <Box>
//           <SidebarContext.Provider
//             value={{
//               toggleSidebar,
//               setToggleSidebar
//             }}>
//             <Sidebar
//               routes={route}
//               display='none'
//               {...rest}
//               openSidebar={openSidebar}
//               setOpenSidebar={setOpenSidebar}
//               largeLogo={largeLogo}
//             />
//             <Box
//               float='right'
//               minHeight='100vh'
//               height='100%'
//               overflow='auto'
//               position='relative'
//               maxHeight='100%'
//               w={{ base: '100%', xl: openSidebar === true ? 'calc( 100% - 300px )' : 'calc( 100% - 88px )' }}
//               maxWidth={{ base: '100%', xl: openSidebar === true ? 'calc( 100% - 300px )' : 'calc( 100% - 88px )' }}
//               transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
//               transitionDuration='.2s, .2s, .35s'
//               transitionProperty='top, bottom, width'
//               transitionTimingFunction='linear, linear, ease'>
//               <Portal>
//                 <Box className='header'>
//                   <Navbar
//                     onOpen={onOpen}
//                     logoText={'Horizon UI Dashboard PRO'}
//                     brandText={getActiveRoute(route)}
//                     fixed={fixed}
//                     routes={route}
//                     largeLogo={largeLogo}
//                     openSidebar={openSidebar}
//                     setOpenSidebar={setOpenSidebar}
//                     {...rest}
//                   />
//                 </Box>
//               </Portal>
//               <Box pt={{ base: "150px", md: "95px", xl: "95px" }}>
//                 <Box mx='auto' pe='20px' minH='84vh' pt='50px'>
//                   <Suspense fallback={
//                     <Flex justifyContent={'center'} alignItems={'center'} width="100%">
//                       <Spinner />
//                     </Flex>
//                   }>
//                     <Routes>
//                       {getRoutes(route)}
//                       <Route path="/*" element={<Navigate to="/default" />} />
//                     </Routes>
//                   </Suspense>
//                 </Box>
//               </Box>
//               <Box>
//                 <Footer />
//               </Box>
//             </Box>
//           </SidebarContext.Provider>
//         </Box>
//       </Box>
//     );
//   }
  
// // Chakra imports
// import { Portal, Box, useDisclosure, Text, Button, Link, Flex, Icon } from '@chakra-ui/react';
// import Footer from 'components/footer/FooterAdmin.js';
// // Layout components
// import Navbar from 'components/navbar/NavbarAdmin.js';
// import Sidebar from 'components/sidebar/Sidebar.js';
// import { SidebarContext } from 'contexts/SidebarContext';
// import React, { Suspense, useEffect, useState } from 'react';
// import { Navigate, Route, Routes } from 'react-router-dom';
// import { ROLE_PATH } from '../../roles';
// import newRoute from 'routes.js';
// import { MdHome, MdLock } from 'react-icons/md';
// import Spinner from 'components/spinner/Spinner';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchImage } from '../../redux/imageSlice';
// import { getApi } from 'services/api';
// import DynamicPage from 'views/admin/dynamicPage';
// import { LuChevronRightCircle } from 'react-icons/lu';
// import { FaCalendarAlt } from 'react-icons/fa';
// import { MdOutlineSupportAgent } from "react-icons/md";

// const MainDashboard = React.lazy(() => import("views/admin/default"));
// const SignInCentered = React.lazy(() => import("views/auth/signIn"));
// const Calender = React.lazy(() => import("views/admin/calender"));
// const UserView = React.lazy(() => import("views/admin/users/View"));
// const Tickets = React.lazy(() => import("views/admin/ticket"));
// const TicketView = React.lazy(() => import("views/admin/ticket/components/eventView"));
// const AddTicket = React.lazy(() => import("views/admin/ticket/components/AddEdit"));
// const EditTicket = React.lazy(() => import("views/admin/ticket/components/edit"));
// // Custom Chakra theme
// export default function User(props) {
//     const { ...rest } = props;
//     // states and functions
//     const [fixed] = useState(false);
//     const [toggleSidebar, setToggleSidebar] = useState(false);
//     const [route, setRoute] = useState();
//     const [openSidebar, setOpenSidebar] = useState(true)
//     const user = JSON.parse(localStorage.getItem("user"))
//     // functions for changing the states from components
//     const getRoute = () => {
//         return window.location.pathname !== '/admin/full-screen-maps';
//     };


//     const fetchRoute = async () => {
//         let response = await getApi("api/route/");
//         console.log("🚀 ~ fetchRoute ~ response:", response.data)
//         setRoute(routes);
//     };

//     const pathName = (name) => {
//         return `/${name.toLowerCase().replace(/ /g, '-')}`;
//     }


//     useEffect(() => {
//         fetchRoute();
//     }, []);

//     // const layoutName = user?.roles?.map(item => `/${item.roleName}`)

//     const filterAccess = (rolesData) => {
//         return rolesData?.map(role => {
//             role.access = role?.access?.filter(access => access.view);
//             return role;
//         });
//     };

//     // Example usage:
//     const updatedRolesData = filterAccess(user?.roles);
//     let access = []
//     updatedRolesData?.map((item) => {
//         item?.access?.map((data) => access.push(data))
//     })

//     let mergedPermissions = {};

//     access.forEach((permission) => {
//         const { title, ...rest } = permission;

//         if (!mergedPermissions[title]) {
//             mergedPermissions[title] = { ...rest };
//         } else {
//             // Merge with priority to true values
//             Object.keys(rest).forEach((key) => {
//                 if (mergedPermissions[title][key] !== true) {
//                     mergedPermissions[title][key] = rest[key];
//                 }
//             });
//         }
//     });
//     let routes =
//     [
//         {
//             name: "Dashboard",
//             layout: [ROLE_PATH.user],
//             path: "/default",
//             icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
//             component: MainDashboard,
//         }, {
//             name: "Sign In",
//             layout: "/auth",
//             path: "/sign-in",
//             icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
//             component: SignInCentered,
//         },
//         {
//             name: "Calender",
//             layout: [ROLE_PATH.user],
//             path: "/calender",
//             icon: <Icon as={FaCalendarAlt} width='20px' height='20px' color='inherit' />,
//             component: Calender,
//         },
//         {
//             name: "User View",
//             layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
//             parentName: "Email",
//             under: "user",
//             path: "/userView/:id",
//             component: UserView,
//         },
//         {
//             name: "Tickets",
//             layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
//             path: "/ticket",
//             icon: <Icon as={MdOutlineSupportAgent} width='20px' height='20px' color='inherit' />,
//             component: Tickets,
//           },
//         {
//             name: "Tickets",
//             layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
//             under: "Ticket",
//             parentName: "Ticket",
//             path: "/ticket/notesview/:id",
//             component: TicketView,
//           },
//           {
//             name: "Tickets",
//             layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
//             under: "Ticket",
//             parentName: "Ticket",
//             path: "/ticket/add",
//             component: AddTicket,
//           },
//           {
//             name: "Tickets",
//             layout: [ROLE_PATH.superAdmin, ROLE_PATH.user],
//             under: "Ticket",
//             parentName: "Ticket",
//             path: "/ticket/edit/:id",
//             component: EditTicket,
//           },
//     ]
//     routes?.map((item, i) => {
//         if (!newRoute.some(route => route.name === item.name)) {
//             return (
//                 newRoute.push({
//                     name: item?.moduleName,
//                     layout: [ROLE_PATH.user],
//                     path: pathName(item.name),
//                     icon: <Icon as={LuChevronRightCircle} width='20px' height='20px' color='inherit' />,
//                     component: DynamicPage,
//                 })
//             )
//         }
//     })
//     const accessRoute = newRoute?.filter(item => Object.keys(mergedPermissions)?.find(data => (data?.toLowerCase() === item?.name?.toLowerCase()) || (data?.toLowerCase() === item.parentName?.toLowerCase())))

//     routes.push(...accessRoute)
//     console.log("🚀 ~ routes111:", routes)

//     const getActiveRoute = (routes) => {
//         let activeRoute = 'Prolink';
//         for (let i = 0; i < routes.length; i++) {
//             if (routes[i].collapse) {
//                 let collapseActiveRoute = getActiveRoute(routes[i].items);
//                 if (collapseActiveRoute !== activeRoute) {
//                     return collapseActiveRoute;
//                 }
//             } else if (routes[i].category) {
//                 let categoryActiveRoute = getActiveRoute(routes[i].items);
//                 if (categoryActiveRoute !== activeRoute) {
//                     return categoryActiveRoute;
//                 }
//             } else {
//                 if (window.location.href.indexOf(routes[i].path.replace("/:id", "")) !== -1) {
//                     return routes[i].name;
//                 }
//             }
//         }
//         return activeRoute;
//     };
//     const under = (routes) => {
//         let activeRoute = false
//         for (let i = 0; i < routes?.length; i++) {
//             if (routes[i]?.collapse) {
//                 let collapseActiveRoute = getActiveRoute(routes[i]?.items);
//                 if (collapseActiveRoute !== activeRoute) {
//                     return collapseActiveRoute;
//                 }
//             } else if (routes[i]?.category) {
//                 let categoryActiveRoute = getActiveRoute(routes[i]?.items);
//                 if (categoryActiveRoute !== activeRoute) {
//                     return categoryActiveRoute;
//                 }
//             } else {
//                 if (window.location.href?.indexOf(routes[i]?.path?.replace("/:id", "")) !== -1) {
//                     return routes[i];
//                 }
//             }
//         }
//         return activeRoute;
//     };

//     const getActiveNavbar = (routes) => {
//         let activeNavbar = false;
//         for (let i = 0; i < routes.length; i++) {
//             if (routes[i].collapse) {
//                 let collapseActiveNavbar = getActiveNavbar(routes[i].items);
//                 if (collapseActiveNavbar !== activeNavbar) {
//                     return collapseActiveNavbar;
//                 }
//             } else if (routes[i].category) {
//                 let categoryActiveNavbar = getActiveNavbar(routes[i].items);
//                 if (categoryActiveNavbar !== activeNavbar) {
//                     return categoryActiveNavbar;
//                 }
//             } else {
//                 if (window.location.href.indexOf(routes[i].path) !== -1) {
//                     return routes[i].secondary;
//                 }
//             }
//         }
//         return activeNavbar;
//     };
//     const getActiveNavbarText = (routes) => {
//         let activeNavbar = false;
//         for (let i = 0; i < routes.length; i++) {
//             if (routes[i].collapse) {
//                 let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
//                 if (collapseActiveNavbar !== activeNavbar) {
//                     return collapseActiveNavbar;
//                 }
//             } else if (routes[i].category) {
//                 let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
//                 if (categoryActiveNavbar !== activeNavbar) {
//                     return categoryActiveNavbar;
//                 }
//             } else {
//                 if (window.location.href.indexOf(routes[i].path) !== -1) {
//                     return routes[i].messageNavbar;
//                 }
//             }
//         }
//         return activeNavbar;
//     };


//     const getRoutes = (routes) => {
//         return routes?.map((prop, key) => {
//             // if (!prop.under && prop.layout === '/admin') {
//             if (!prop?.under && prop?.layout !== '/auth') {
//                 return <Route path={prop?.path} element={prop && <prop.component />} key={key} />;
//             } else if (prop?.under) {
//                 return <Route path={prop?.path} element={prop && <prop.component />} key={key} />
//             }
//             if (prop?.collapse) {
//                 return getRoutes(prop?.items);
//             }
//             if (prop?.category) {
//                 return getRoutes(prop?.items);
//             } else {
//                 return null;
//             }
//         });
//     };
//     document.documentElement.dir = 'ltr';
//     const { onOpen } = useDisclosure();
//     document.documentElement.dir = 'ltr';

//     const dispatch = useDispatch();

//     useEffect(() => {
//         // Dispatch the fetchRoles action on component mount
//         dispatch(fetchImage());
//     }, [dispatch]);

//     const largeLogo = useSelector((state) => state?.images?.images?.filter(item => item?.isActive === true));


//     return (
//         <Box>
//             <Box>
//                 <SidebarContext.Provider
//                     value={{
//                         toggleSidebar,
//                         setToggleSidebar
//                     }}>
//                     <Sidebar routes={routes} display='none' {...rest} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} largeLogo={largeLogo} />
//                     <Box
//                         float='right'
//                         minHeight='100vh'
//                         height='100%'
//                         overflow='auto'
//                         position='relative'
//                         maxHeight='100%'
//                         // w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
//                         w={{ base: '100%', xl: openSidebar === true ? 'calc( 100% - 300px )' : 'calc( 100% - 88px )' }}
//                         maxWidth={{ base: '100%', xl: openSidebar === true ? 'calc( 100% - 300px )' : 'calc( 100% - 88px )' }}
//                         transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
//                         transitionDuration='.2s, .2s, .35s'
//                         transitionProperty='top, bottom, width'
//                         transitionTimingFunction='linear, linear, ease'>
//                         <Portal>
//                             <Box className='header'>
//                                 <Navbar
//                                     onOpen={onOpen}
//                                     logoText={'Horizon UI Dashboard PRO'}
//                                     brandText={getActiveRoute(routes)}
//                                     secondary={getActiveNavbar(routes)}
//                                     message={getActiveNavbarText(routes)}
//                                     fixed={fixed}
//                                     routes={routes}
//                                     under={under(routes)}
//                                     largeLogo={largeLogo}
//                                     openSidebar={openSidebar} setOpenSidebar={setOpenSidebar}
//                                     {...rest}
//                                 />
//                             </Box>
//                         </Portal>
//                         <Box pt={{ base: "150px", md: "95px", xl: "95px" }}>
//                             {getRoute() ? (
//                                 <Box mx='auto' pe='20px' minH='84vh' pt='50px' style={{ padding: openSidebar ? '8px 20px 8px 20px' : '8px 20px' }}>
//                                     <Suspense fallback={
//                                         <Flex justifyContent={'center'} alignItems={'center'} width="100%" >
//                                             <Spinner />
//                                         </Flex>
//                                     }>
//                                         <Routes>
//                                             {getRoutes(routes)}
//                                             <Route path="/*" element={<Navigate to="/default" />} />
//                                         </Routes>
//                                     </Suspense>
//                                 </Box>
//                             ) : null}
//                         </Box>
//                         <Box>
//                             <Footer />
//                         </Box>
//                     </Box>
//                 </SidebarContext.Provider>
//             </Box>
//         </Box>
//     );
// }
